<template>
  <div class="flag-icon" :class="flag.classes" :title="flag.title"></div>
</template>

<script>
  import locale from '../../../public/locale.json';
  
  export default {
    name: 'Flag',
    data() {
      return {
        flag: {
          sizes: {
            nano: 10,
            micro: 15,
            mini: 20,
            small: 25,
            default: 30,
            medium: 35,
            large: 40,
            huge: 45
          },
          size: {
            name: 'default',
            value: 30
          },
          title: '',
          width: null,
          height: null,
          classes: ['code--unknown']
        },
        unknown: true,
        locale: {},
        codes: ['ad','ae','af','ag','ai','al','am','ao','aq','ar','as','at','au','aw','ax','az','ba','bb','bd','be','bf','bg','bh','bi','bj','bl','bm','bn','bo','bq','br','bs','bt','bv','bw','by','bz','ca','cc','cd','cf','cg','ch','ci','ck','cl','cm','cn','co','cr','cu','cv','cw','cx','cy','cz','de','dj','dk','dm','do','dz','ec','ee','eg','eh','er','es','et','eu','fi','fj','fk','fm','fo','fr','ga','gb','gd','ge','gf','gg','gh','gi','gl','gm','gn','gp','gq','gr','gs','gt','gu','gw','gy','hk','hm','hn','hr','ht','hu','id','ie','il','im','in','io','iq','ir','is','it','je','jm','jo','jp','ke','kg','kh','ki','km','kn','kp','kr','kw','ky','kz','la','lb','lc','li','lk','lr','ls','lt','lu','lv','ly','ma','mc','md','me','mf','mg','mh','mk','ml','mm','mn','mo','mp','mq','mr','ms','mt','mu','mv','mw','mx','my','mz','na','nc','ne','nf','ng','ni','nl','no','np','nr','nu','nz','om','pa','pe','pf','pg','ph','pk','pl','pm','pn','pr','ps','pt','pw','py','qa','re','ro','rs','ru','rw','sa','sb','sc','sd','se','sg','sh','si','sj','sk','sl','sm','sn','so','sr','ss','st','sv','sx','sy','sz','tc','td','tf','tg','th','tj','tk','tl','tm','tn','to','tr','tt','tv','tw','tz','ua','ug','um','us','uy','uz','va','vc','ve','vg','vi','vn','vu','wf','ws','ye','yt','za','zm','zw'],
        iso: {}
      }
    },
    props: {
      code: {
        default: 'unknown'
      },
      size: {
        default: 'default'
      },
      type: {
        default: 'country'
      },
      title: {
        default: ''
      },
      width: {
        default: null
      },
      height: {
        default: null
      },
      disabled: {
        default: false
      }
    },
    watch: {
      code(code) {
        this.SetClassList(code, this.flag.size.name);
      },
      title(title) {
        this.flag.title = title;
      }
    },
    created() {
      for (let code in locale) this.locale[code.toLowerCase()] = locale[code];
      this.type = this.type == 'language' ? 'language' : 'country';
      if (Object.keys(this.flag.sizes).includes(this.size)) {
        this.flag.size.name = this.size;
      }
      this.flag.title = this.title || this.flag.title;
      this.SetClassList(this.code, this.flag.size.name);
    },
    methods: {
      SetClassList(code, size) {
        const classes = [];
        code = code.toLowerCase();
        size = size || this.flag.classes.find(classname => {
          classname.startsWith('size');
        });
        if (this.type == 'language') {
          code = code.split(/-|_/g).slice(-1)[0];
        }
        if (this.codes.includes(code)) {
          classes.push('code--' + code);
        }
        if (size && Object.keys(this.flag.sizes).includes(size)) {
          classes.push('size--' + size);
        }
        this.flag.classes = classes;
      },
      language(string) {
        if (string) return string.match(/[^ (]*/)[0];
      },
      country(string) {
        if (string) return string.match(/\(([^)]+)\)/)[1];
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/style/variables/flags';
  
  .flag {
    &-icon {
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.disabled {
        cursor: default;
        filter: grayscale(1) brightness(3) contrast(2);
      }

      &:not(.disabled) {
        
      }

      &.size {
        &--nano {
          width: 10px;
          height: 10px;
        }
        &--micro {
          width: 15px;
          height: 15px;
        }
        &--mini {
          width: 20px;
          height: 20px;
        }
        &--small {
          width: 25px;
          height: 25px;
        }
        &--default {
          width: 30px;
          height: 30px;
        }
        &--medium {
          width: 35px;
          height: 35px;
        }
        &--large {
          width: 40px;
          height: 40px;
        }
        &--huge {
          width: 45px;
          height: 45px;
        }
      }

      &.code {
        &--ad {
          background-image: $flag-ad;
        }
        &--ae {
          background-image: $flag-ae;
        }
        &--af {
          background-image: $flag-af;
        }
        &--ag {
          background-image: $flag-ag;
        }
        &--ai {
          background-image: $flag-ai;
        }
        &--al {
          background-image: $flag-al;
        }
        &--am {
          background-image: $flag-am;
        }
        &--ao {
          background-image: $flag-ao;
        }
        &--aq {
          background-image: $flag-aq;
        }
        &--ar {
          background-image: $flag-ar;
        }
        &--as {
          background-image: $flag-as;
        }
        &--at {
          background-image: $flag-at;
        }
        &--au {
          background-image: $flag-au;
        }
        &--aw {
          background-image: $flag-aw;
        }
        &--ax {
          background-image: $flag-ax;
        }
        &--az {
          background-image: $flag-az;
        }
        &--ba {
          background-image: $flag-ba;
        }
        &--bb {
          background-image: $flag-bb;
        }
        &--bd {
          background-image: $flag-bd;
        }
        &--be {
          background-image: $flag-be;
        }
        &--bf {
          background-image: $flag-bf;
        }
        &--bg {
          background-image: $flag-bg;
        }
        &--bh {
          background-image: $flag-bh;
        }
        &--bi {
          background-image: $flag-bi;
        }
        &--bj {
          background-image: $flag-bj;
        }
        &--bl {
          background-image: $flag-bl;
        }
        &--bm {
          background-image: $flag-bm;
        }
        &--bn {
          background-image: $flag-bn;
        }
        &--bo {
          background-image: $flag-bo;
        }
        &--bq {
          background-image: $flag-bq;
        }
        &--br {
          background-image: $flag-br;
        }
        &--bs {
          background-image: $flag-bs;
        }
        &--bt {
          background-image: $flag-bt;
        }
        &--bv {
          background-image: $flag-bv;
        }
        &--bw {
          background-image: $flag-bw;
        }
        &--by {
          background-image: $flag-by;
        }
        &--bz {
          background-image: $flag-bz;
        }
        &--ca {
          background-image: $flag-ca;
        }
        &--cc {
          background-image: $flag-cc;
        }
        &--cd {
          background-image: $flag-cd;
        }
        &--cf {
          background-image: $flag-cf;
        }
        &--cg {
          background-image: $flag-cg;
        }
        &--ch {
          background-image: $flag-ch;
        }
        &--ci {
          background-image: $flag-ci;
        }
        &--ck {
          background-image: $flag-ck;
        }
        &--cl {
          background-image: $flag-cl;
        }
        &--cm {
          background-image: $flag-cm;
        }
        &--cn {
          background-image: $flag-cn;
        }
        &--co {
          background-image: $flag-co;
        }
        &--cr {
          background-image: $flag-cr;
        }
        &--cu {
          background-image: $flag-cu;
        }
        &--cv {
          background-image: $flag-cv;
        }
        &--cw {
          background-image: $flag-cw;
        }
        &--cx {
          background-image: $flag-cx;
        }
        &--cy {
          background-image: $flag-cy;
        }
        &--cz {
          background-image: $flag-cz;
        }
        &--de {
          background-image: $flag-de;
        }
        &--dj {
          background-image: $flag-dj;
        }
        &--dk {
          background-image: $flag-dk;
        }
        &--dm {
          background-image: $flag-dm;
        }
        &--do {
          background-image: $flag-do;
        }
        &--dz {
          background-image: $flag-dz;
        }
        &--ec {
          background-image: $flag-ec;
        }
        &--ee {
          background-image: $flag-ee;
        }
        &--eg {
          background-image: $flag-eg;
        }
        &--eh {
          background-image: $flag-eh;
        }
        &--er {
          background-image: $flag-er;
        }
        &--es {
          background-image: $flag-es;
        }
        &--et {
          background-image: $flag-et;
        }
        &--eu {
          background-image: $flag-eu;
        }
        &--fi {
          background-image: $flag-fi;
        }
        &--fj {
          background-image: $flag-fj;
        }
        &--fk {
          background-image: $flag-fk;
        }
        &--fm {
          background-image: $flag-fm;
        }
        &--fo {
          background-image: $flag-fo;
        }
        &--fr {
          background-image: $flag-fr;
        }
        &--ga {
          background-image: $flag-ga;
        }
        &--gb {
          background-image: $flag-gb;
        }
        &--gd {
          background-image: $flag-gd;
        }
        &--ge {
          background-image: $flag-ge;
        }
        &--gf {
          background-image: $flag-gf;
        }
        &--gg {
          background-image: $flag-gg;
        }
        &--gh {
          background-image: $flag-gh;
        }
        &--gi {
          background-image: $flag-gi;
        }
        &--gl {
          background-image: $flag-gl;
        }
        &--gm {
          background-image: $flag-gm;
        }
        &--gn {
          background-image: $flag-gn;
        }
        &--gp {
          background-image: $flag-gp;
        }
        &--gq {
          background-image: $flag-gq;
        }
        &--gr {
          background-image: $flag-gr;
        }
        &--gs {
          background-image: $flag-gs;
        }
        &--gt {
          background-image: $flag-gt;
        }
        &--gu {
          background-image: $flag-gu;
        }
        &--gw {
          background-image: $flag-gw;
        }
        &--gy {
          background-image: $flag-gy;
        }
        &--hk {
          background-image: $flag-hk;
        }
        &--hm {
          background-image: $flag-hm;
        }
        &--hn {
          background-image: $flag-hn;
        }
        &--hr {
          background-image: $flag-hr;
        }
        &--ht {
          background-image: $flag-ht;
        }
        &--hu {
          background-image: $flag-hu;
        }
        &--id {
          background-image: $flag-id;
        }
        &--ie {
          background-image: $flag-ie;
        }
        &--il {
          background-image: $flag-il;
        }
        &--im {
          background-image: $flag-im;
        }
        &--in {
          background-image: $flag-in;
        }
        &--io {
          background-image: $flag-io;
        }
        &--iq {
          background-image: $flag-iq;
        }
        &--ir {
          background-image: $flag-ir;
        }
        &--is {
          background-image: $flag-is;
        }
        &--it {
          background-image: $flag-it;
        }
        &--je {
          background-image: $flag-je;
        }
        &--jm {
          background-image: $flag-jm;
        }
        &--jo {
          background-image: $flag-jo;
        }
        &--jp {
          background-image: $flag-jp;
        }
        &--ke {
          background-image: $flag-ke;
        }
        &--kg {
          background-image: $flag-kg;
        }
        &--kh {
          background-image: $flag-kh;
        }
        &--ki {
          background-image: $flag-ki;
        }
        &--km {
          background-image: $flag-km;
        }
        &--kn {
          background-image: $flag-kn;
        }
        &--kp {
          background-image: $flag-kp;
        }
        &--kr {
          background-image: $flag-kr;
        }
        &--kw {
          background-image: $flag-kw;
        }
        &--ky {
          background-image: $flag-ky;
        }
        &--kz {
          background-image: $flag-kz;
        }
        &--la {
          background-image: $flag-la;
        }
        &--lb {
          background-image: $flag-lb;
        }
        &--lc {
          background-image: $flag-lc;
        }
        &--li {
          background-image: $flag-li;
        }
        &--lk {
          background-image: $flag-lk;
        }
        &--lr {
          background-image: $flag-lr;
        }
        &--ls {
          background-image: $flag-ls;
        }
        &--lt {
          background-image: $flag-lt;
        }
        &--lu {
          background-image: $flag-lu;
        }
        &--lv {
          background-image: $flag-lv;
        }
        &--ly {
          background-image: $flag-ly;
        }
        &--ma {
          background-image: $flag-ma;
        }
        &--mc {
          background-image: $flag-mc;
        }
        &--md {
          background-image: $flag-md;
        }
        &--me {
          background-image: $flag-me;
        }
        &--mf {
          background-image: $flag-mf;
        }
        &--mg {
          background-image: $flag-mg;
        }
        &--mh {
          background-image: $flag-mh;
        }
        &--mk {
          background-image: $flag-mk;
        }
        &--ml {
          background-image: $flag-ml;
        }
        &--mm {
          background-image: $flag-mm;
        }
        &--mn {
          background-image: $flag-mn;
        }
        &--mo {
          background-image: $flag-mo;
        }
        &--mp {
          background-image: $flag-mp;
        }
        &--mq {
          background-image: $flag-mq;
        }
        &--mr {
          background-image: $flag-mr;
        }
        &--ms {
          background-image: $flag-ms;
        }
        &--mt {
          background-image: $flag-mt;
        }
        &--mu {
          background-image: $flag-mu;
        }
        &--mv {
          background-image: $flag-mv;
        }
        &--mw {
          background-image: $flag-mw;
        }
        &--mx {
          background-image: $flag-mx;
        }
        &--my {
          background-image: $flag-my;
        }
        &--mz {
          background-image: $flag-mz;
        }
        &--na {
          background-image: $flag-na;
        }
        &--nc {
          background-image: $flag-nc;
        }
        &--ne {
          background-image: $flag-ne;
        }
        &--nf {
          background-image: $flag-nf;
        }
        &--ng {
          background-image: $flag-ng;
        }
        &--ni {
          background-image: $flag-ni;
        }
        &--nl {
          background-image: $flag-nl;
        }
        &--no {
          background-image: $flag-no;
        }
        &--np {
          background-image: $flag-np;
        }
        &--nr {
          background-image: $flag-nr;
        }
        &--nu {
          background-image: $flag-nu;
        }
        &--nz {
          background-image: $flag-nz;
        }
        &--om {
          background-image: $flag-om;
        }
        &--pa {
          background-image: $flag-pa;
        }
        &--pe {
          background-image: $flag-pe;
        }
        &--pf {
          background-image: $flag-pf;
        }
        &--pg {
          background-image: $flag-pg;
        }
        &--ph {
          background-image: $flag-ph;
        }
        &--pk {
          background-image: $flag-pk;
        }
        &--pl {
          background-image: $flag-pl;
        }
        &--pm {
          background-image: $flag-pm;
        }
        &--pn {
          background-image: $flag-pn;
        }
        &--pr {
          background-image: $flag-pr;
        }
        &--ps {
          background-image: $flag-ps;
        }
        &--pt {
          background-image: $flag-pt;
        }
        &--pw {
          background-image: $flag-pw;
        }
        &--py {
          background-image: $flag-py;
        }
        &--qa {
          background-image: $flag-qa;
        }
        &--re {
          background-image: $flag-re;
        }
        &--ro {
          background-image: $flag-ro;
        }
        &--rs {
          background-image: $flag-rs;
        }
        &--ru {
          background-image: $flag-ru;
        }
        &--rw {
          background-image: $flag-rw;
        }
        &--sa {
          background-image: $flag-sa;
        }
        &--sb {
          background-image: $flag-sb;
        }
        &--sc {
          background-image: $flag-sc;
        }
        &--sd {
          background-image: $flag-sd;
        }
        &--se {
          background-image: $flag-se;
        }
        &--sg {
          background-image: $flag-sg;
        }
        &--sh {
          background-image: $flag-sh;
        }
        &--si {
          background-image: $flag-si;
        }
        &--sj {
          background-image: $flag-sj;
        }
        &--sk {
          background-image: $flag-sk;
        }
        &--sl {
          background-image: $flag-sl;
        }
        &--sm {
          background-image: $flag-sm;
        }
        &--sn {
          background-image: $flag-sn;
        }
        &--so {
          background-image: $flag-so;
        }
        &--sr {
          background-image: $flag-sr;
        }
        &--ss {
          background-image: $flag-ss;
        }
        &--st {
          background-image: $flag-st;
        }
        &--sv {
          background-image: $flag-sv;
        }
        &--sx {
          background-image: $flag-sx;
        }
        &--sy {
          background-image: $flag-sy;
        }
        &--sz {
          background-image: $flag-sz;
        }
        &--tc {
          background-image: $flag-tc;
        }
        &--td {
          background-image: $flag-td;
        }
        &--tf {
          background-image: $flag-tf;
        }
        &--tg {
          background-image: $flag-tg;
        }
        &--th {
          background-image: $flag-th;
        }
        &--tj {
          background-image: $flag-tj;
        }
        &--tk {
          background-image: $flag-tk;
        }
        &--tl {
          background-image: $flag-tl;
        }
        &--tm {
          background-image: $flag-tm;
        }
        &--tn {
          background-image: $flag-tn;
        }
        &--to {
          background-image: $flag-to;
        }
        &--tr {
          background-image: $flag-tr;
        }
        &--tt {
          background-image: $flag-tt;
        }
        &--tv {
          background-image: $flag-tv;
        }
        &--tw {
          background-image: $flag-tw;
        }
        &--tz {
          background-image: $flag-tz;
        }
        &--ua {
          background-image: $flag-ua;
        }
        &--ug {
          background-image: $flag-ug;
        }
        &--um {
          background-image: $flag-um;
        }
        &--unknown {
          background-image: $flag-unknown;
        }
        &--us {
          background-image: $flag-us;
        }
        &--uy {
          background-image: $flag-uy;
        }
        &--uz {
          background-image: $flag-uz;
        }
        &--va {
          background-image: $flag-va;
        }
        &--vc {
          background-image: $flag-vc;
        }
        &--ve {
          background-image: $flag-ve;
        }
        &--vg {
          background-image: $flag-vg;
        }
        &--vi {
          background-image: $flag-vi;
        }
        &--vn {
          background-image: $flag-vn;
        }
        &--vu {
          background-image: $flag-vu;
        }
        &--wf {
          background-image: $flag-wf;
        }
        &--ws {
          background-image: $flag-ws;
        }
        &--ye {
          background-image: $flag-ye;
        }
        &--yt {
          background-image: $flag-yt;
        }
        &--za {
          background-image: $flag-za;
        }
        &--zm {
          background-image: $flag-zm;
        }
        &--zw {
          background-image: $flag-zw;
        }
      }
    }
  }
</style>
